@import "../../../sass/common/variables.scss";

.comment-view-container {
  padding-bottom: 16px;

  .comments-container {
    height: 50vh;
    max-height: 50vh;
    overflow-y: auto;

    .MuiPaper-root {
      padding: 6px 10px;
    }

    .no-comments-info-text,
    .loading {
      display: grid;
      place-items: center;
      font-size: 14px;
      color: $filters-font-color;
      width: 100%;
      height: 50%;
    }

    .loading {
      .MuiCircularProgress-colorPrimary {
        color: $solver-range-picker-handles-color;
      }
    }
  }

  .MuiTimeline-root {
    align-items: flex-start;
    padding: 6px;
  }

  .MuiTimelineItem-root {
    min-height: 0;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 93px auto auto;

    .MuiTimelineOppositeContent-root {
      margin-right: unset;
    }

    .MuiTimelineContent-root {
      position: relative;
      padding: 6px 20px 6px 16px;
    }
  }

  .dark-background {
    .MuiPaper-root {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .comment-underlay-section {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    .MuiIconButton-root {
      padding: 2px;
      margin-left: 2px;
      border-radius: 2px;
      position: absolute;
      right: 0;
      .MuiSvgIcon-root {
        width: 14px;
        height: 14px;
        fill: $filters-font-color;
      }

      .MuiCircularProgress-root {
        width: 10px !important;
        height: 10px !important;
      }

      .MuiCircularProgress-svg {
        width: 10px;
        height: 10px;
      }
    }
  }

  .comment-message {
    font-size: 12px;
    color: $filters-font-color;
    max-width: 20vw;
    overflow-wrap: break-word;
    white-space: pre-line;
  }

  .name-time-container {
    .name {
      font-size: 12px;
      word-break: break-word;
    }

    .time {
      font-size: 10px;
      font-weight: 100;
    }
  }

  .current-user {
    border-color: $solver-range-picker-handles-color;
  }

  .MuiDivider-root {
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.05);
    margin: 0vw 2vw;
  }

  .add-comment-section {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr;

    div {
      display: grid;
    }

    .user-icon {
      justify-content: flex-start;
      padding-left: 16px;
      padding-top: 4px;

      .MuiTimelineDot-outlinedGrey {
        box-shadow: unset;
        border-color: $solver-range-picker-handles-color;
      }
    }

    .text-field-section {
      padding: 11px 11px;

      .MuiInputBase-root {
        font-size: 12px;
      }

      .MuiOutlinedInput-multiline {
        padding: 14px 14px;
      }

      .MuiInputLabel-outlined {
        font-size: 12px;
        transform: translate(10px, 14px) scale(1);
      }

      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(17px, -6px) scale(0.75);
      }

      .MuiFormLabel-root.Mui-focused {
        color: $solver-range-picker-handles-color !important;
      }
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $solver-range-picker-handles-color !important;
      }

      .comment-button {
        justify-content: flex-start;
        padding: 8px 0px;

        .MuiButton-label {
          text-transform: none;
          font-size: 10px;
        }

        #save-button {
          background-color: $solver-range-picker-handles-color;
          text-transform: unset;
          .MuiButton-label {
            color: $site-background-color;
          }
        }
      }

      .outlined-button {
        .MuiButton-outlined {
          border: 1px solid $solver-range-picker-handles-color;
        }

        #save-button {
          background-color: unset !important;
          .MuiButton-label {
            color: $solver-range-picker-handles-color;
          }
        }
      }
    }
  }
}

.name-tooltip {
  color: $filters-font-color;
}
