@import "../../../sass/common/variables.scss";

.loading-main {
  width: 100%;
  display: grid;
  place-items: center;

  @keyframes rotatePlane {
    from {
      transform: rotate(360deg) translateX(15px);
    }
    50% {
      transform: rotate(180deg) translateX(15px);
    }
    to {
      transform: rotate(0deg) translateX(15px);
    }
  }

  .card-skeleton {
    width: 100%;
    background: $site-background-color;
    text-align: center;

    .loading-message {
      z-index: 11;
    }
    svg {
      animation: rotatePlane;
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      margin-bottom: 10px;
    }
  }
}
