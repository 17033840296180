@import "../../../../sass/common/variables.scss";

.summary-info {
  display: grid;
  align-content: center;
  justify-content: end;
  grid-auto-flow: column;

  .info-block {
    margin-top: -1vh;
    margin-bottom: -2vh;

    .title {
      font-size: 10px;
    }

    .value {
      font-size: 20px;
      text-align: center;
    }

    .MuiSwitch-colorSecondary.Mui-checked {
      color: $solver-range-picker-handles-color;
    }

    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: $solver-range-picker-handles-color;
    }
  }

  .MuiSelect-root {
    font-size: 12px;
    position: relative;
    margin-left: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.23);
    min-width: 100px;
    max-width: 100px;
  }
}

.scenario-name-with-loading-icon {
  display: grid;
  grid-auto-flow: column;
  place-items: center;
  grid-column-gap: 6px;

  .solver-scenario-in-progress-indicator {
    position: absolute;
  }

  .MuiSvgIcon-root {
    fill: orangered;
  }
}
