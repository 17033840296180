@import "../../../../sass/common/variables.scss";

.filter-v2-container {
  display: grid;
  grid-auto-flow: column;
  .filter-v2-filter-section {
    display: grid;
    grid-auto-flow: column;
  }
}

.auto-complete {
  .MuiAutocomplete-root {
    width: 100px;
  }

  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    min-width: 100px;
  }

  .disruption-filter {
    width: 130px;
  }

  .selected-filter label {
    color: $solver-range-picker-handles-color;
  }

  .MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.8);
    font-size: 12px;
  }

  .MuiInputBase-input {
    font-size: 12px;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid $crew-gantt-border-color;
  }

  .MuiInput-underline::after {
    border-bottom: unset;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid;
  }

  .MuiInputLabel-marginDense {
    transform: translate(0, 17px) scale(1);
  }

  .MuiAutocomplete-endAdornment {
    top: -7px !important;
  }

  .MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(0.75) !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.8);
  }
}

.airline-auto-complete {
  .MuiAutocomplete-root {
    width: 130px;
  }
}

.crewtype-auto-complete {
  .MuiAutocomplete-root {
    width: 130px;
  }
}

.date-time-container {
  .MuiAutocomplete-root {
    width: 190px;
  }

  .MuiAutocomplete-root:hover {
    cursor: text;
  }

  .auto-complete .MuiInputLabel-shrink {
    transform: translate(0, 17px) scale(1) !important;
  }

  .MuiFormLabel-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.8);
  }

  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid;
  }

  .MuiIconButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.54);
  }

  .MuiAutocomplete-input {
    color: transparent;
  }

  .date-range-error-section {
    padding-top: 1vh;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 22px auto;

    .MuiSvgIcon-root {
      fill: $error-color;
      width: 18px;
    }

    .date-range-error-message {
      font-size: 14px;
      color: $error-color;
      padding-top: 1px;
    }
  }

  .date-time-range-picker {
    position: absolute;
    margin-top: 1vh;
    padding: 1vh;
    z-index: 1;

    .to-from-heading {
      display: grid;
      grid-template-columns: 1fr 1fr;
      color: $filters-font-color;
      grid-gap: 30px;
      font-size: 14px;
    }

    .to-from {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;

      .MuiMenu-paper {
        max-height: 20vh;
      }

      .from-date {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        grid-gap: 10px;

        .MuiInputBase-input {
          font-size: 16px;
        }

        .MuiInputBase-root {
          border: 1px solid $solver-modal-header-border-color;
          padding-left: 10px;
          color: $filters-font-color;
        }

        .heading {
          font-size: 10px;
          color: $filters-font-color;
        }

        .date-div {
          position: relative;
          padding: 5px;
          border: 1px solid $solver-modal-header-border-color;
          color: $filters-font-color;

          .calendar-div {
            position: absolute;
            top: 6vh;
            border: 1px solid $solver-modal-header-border-color;
            background: $reserve-duty-background-color;
            width: 19vw;
            z-index: 10;
            left: 0px;
          }
        }
      }
    }
  }
}

.clear-apply-button-container {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  margin-top: 1vh;
  .clear-apply-button {
    display: grid;
    align-content: normal;
    width: 8vh;
    height: 4vh;
    .MuiButton-root {
      text-transform: none;
    }
    .MuiSvgIcon-root {
      fill: $filter-clear-button-color;
      margin-top: 1px;
    }
    .clear-text {
      color: $filters-font-color;
      font-size: 12px;
    }
    .MuiSvgIcon-fontSizeSmall {
      font-size: 15px;
    }

    .apply-button-container {
      display: grid;
      grid-auto-flow: column;
      place-items: center;

      .apply-icon {
        position: relative;
        top: -3px;

        .MuiSvgIcon-root {
          fill: $site-link-color;
        }
      }
    }
  }

  .apply-with-border {
    .MuiButton-outlined {
      padding: 0%;
      border: 1px solid $site-link-color;
    }
  }
}

.MuiAutocomplete-popper {
  .MuiSvgIcon-root {
    width: 1.11vw !important;
    height: 1.11vw !important;
  }
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: $solver-range-picker-handles-color;
  }

  .PrivateSwitchBase-root-197 {
    padding: unset;
  }

  .MuiAutocomplete-option {
    padding-left: 8px;
    padding-right: 8px;
    column-gap: 6px;
    font-size: 12px;
  }
}

.tooltip-text {
  color: $site-font-color;
  font-family: "Roboto", sans-serif;
}
