@import "../../../../sass/common/variables.scss";

.selection-timeline-main {
  position: fixed;
  top: 11vh; /** based on page ruling the mockups, the disruption summary takes 9% of the view height initially **/
  z-index: 3;
}

.selection-timeline-container {
  width: 84vw; /** based on page ruling the mockups, right area for gantt and timeline takes 84% of view width **/
  overflow-x: hidden;
  margin-left: 16vw; /** based on page ruling the mockups, left area for crew selection takes 16% of view width **/
  overflow-y: hidden;

  .selection-main {
    background-color: $white-color;
    border-bottom: 0.5px solid $crew-gantt-border-color;
    height: 8vh;
    width: 16vw;
    position: absolute;
    left: 0%;
    font-size: 12px;
    border-right: 3px solid $crew-gantt-border-color;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1.5fr 5fr 2fr;
    place-items: center;

    /* importing some common select material ui styles customised for control room website*/
    @import "../../../../sass/mui/select/durationSelectionSelect.scss";
    .show-hide-select-container {
      #showHide {
        padding-left: 1.5vw;
        text-align: left;
        width: 8vw;
      }
    }

    .custom-check-box-container {
      justify-self: end;
    }

    .date-range-select-container {
      #dateRangeSelect {
        padding-left: 1.5vw;
        text-align: left;
        width: 2vw;
      }
    }
  }
  .timeline-main {
    background-color: $white-color;
    height: 8vh;
    display: grid;
    place-items: center;
    border-bottom: 0.5px solid $crew-gantt-border-color;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    scroll-behavior: smooth;
    will-change: transform;
    .day-info {
      display: flex;
      justify-content: space-between;

      .day {
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        letter-spacing: 0px;
        color: $filters-font-color;
        opacity: 1;
      }
      .hour-info {
        display: flex;
        justify-content: space-between;

        .hour {
          text-align: left;
          font-weight: 400;
          font-size: 10px;
          font-family: "Roboto", sans-serif;
          letter-spacing: 0px;
          color: $filters-font-color;
          opacity: 1;
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: 1px;
    height: 1px;
    background-color: $white-color;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $white-color;
  }
}

.showhide-select-option,
.daterange-select-option {
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #616161;
  opacity: 1;
  padding: 3px;
  padding-left: 5px;
  cursor: pointer;
}

.daterange-select-option:disabled {
  font-weight: 100;
  cursor: default;
}
