@import "../../../../../sass/common/variables.scss";

.run-solver-progress-toast {
  color: $filters-font-color;

  .scenario-detail {
    .scenario-name-label {
      color: $site-link-color;
      word-break: break-word;
    }
  }

  .scenario-progress-indicator {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 98fr 2fr;
    grid-column-gap: 1vw;
    align-items: center;

    .MuiLinearProgress-colorPrimary {
      background-color: rgba(47, 113, 171, 0.2);
    }

    .MuiLinearProgress-barColorPrimary {
      background-color: rgb(47, 113, 171);
    }

    .percentage-completed {
      font-size: 14px;
    }
  }
}
