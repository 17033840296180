@import "../../../sass/common/variables.scss";

.violation-message-drilled-down-conatiner {
  display: grid;
  height: 100vh;
  grid-template-rows: 8vh auto;

  .contextual-information {
    display: grid;
    color: $filters-font-color;
    padding: 1vh 2vw 0vw 2.25vw;
    grid-template-rows: 1fr 3fr;
    .contextual-information-header {
      font-size: 14px;
    }

    .contextual-information-info {
      font-size: 12px;
      padding-left: 1vw;
    }
  }

  .violation-message-drilled-down-body {
    padding: 5vh 1vw 0vw 2.25vw;
    .body-header {
      color: $filters-font-color;
      font-size: 16px;
    }

    .MuiAccordionSummary-root {
      padding: 0px 16px;
    }

    .MuiAccordionSummary-content {
      font-size: 18px;
    }

    .violation-message {
      max-width: 100%;
      text-align: center;
      word-break: break-word;
      color: $reserve-duty-font-color !important;
      font-size: 14px;
      border-bottom: 1px solid $crew-gantt-border-color;
      padding: 5px;
    }

    .violation-message-container {
      display: grid;
      place-items: center;
      max-height: 300px;
      overflow-y: auto;
      padding: 1vh 1vw;
      .MuiIconButton-root {
        font-size: 14px;
        border-radius: 4px;
        padding: 0px;
      }

      .violation-message {
        display: grid;
        grid-auto-flow: column;
        padding-right: 2vw;
        cursor: pointer;
        height: 7vh;
        place-items: center;

        .comment-bubble-with-count {
          position: relative;
          display: grid;
          place-items: center;
          .comment-count {
            position: absolute;
            color: white;
            font-size: 11px;
            font-weight: 700;
            top: 3px;
          }
        }
      }
    }

    .violation-message-with-chat-view-container {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 0.3fr 0.7fr;

      .violation-picker-section {
        display: grid;
        align-content: start;
        border-right: 1px solid rgba(0, 0, 0, 0.3);
        padding: 12px;
        margin-bottom: 12px;
      }
      .violation-message-container {
        max-height: unset;
        padding: 1vh 0vw 1vh 0.1vw;
      }

      .violation-message {
        padding-right: 0px;
        font-size: 12px;
        height: 7vh;
        border-radius: 5px;
      }
      .comment-bubble-with-count {
        .comment-count {
          font-size: 9px;
          width: 80%;
          top: 4px;
        }
      }
      .MuiTimelineOppositeContent-root {
        padding: 6px 16px 0px 0px;
      }
    }
  }
}

.comment-indicator {
  position: absolute;
  top: 0;
  right: 0;
}

@include sm {
  .violation-message-drilled-down-conatiner {
    width: 56vw;

    .violation-message-drilled-down-body {
      .violation-message-container {
        .violation-message {
          grid-template-columns: 600px 25px;
        }
      }
      .violation-message-with-chat-view-container {
        .violation-message {
          grid-template-columns: 140px 30px;
        }
      }
    }
  }
}

@include md {
  .violation-message-drilled-down-conatiner {
    width: 51vw;

    .violation-message-drilled-down-body {
      .violation-message-container {
        .violation-message {
          grid-template-columns: 600px 25px;
        }
      }
      .violation-message-with-chat-view-container {
        .violation-message {
          grid-template-columns: 140px 30px;
        }
      }
    }
  }
}

@include xl2 {
  .violation-message-drilled-down-conatiner {
    width: 50vw;

    .violation-message-drilled-down-body {
      .violation-message-container {
        .violation-message {
          grid-template-columns: 600px 25px;
        }
      }
      .violation-message-with-chat-view-container {
        .violation-message {
          grid-template-columns: 140px 30px;
        }
      }
    }
  }
}

@include lg {
  .violation-message-drilled-down-conatiner {
    width: 50vw;

    .violation-message-drilled-down-body {
      .violation-message-container {
        .violation-message {
          grid-template-columns: 725px 45px;
        }
      }
      .violation-message-with-chat-view-container {
        .violation-message {
          grid-template-columns: 190px 45px;
        }
      }
    }
  }
}
