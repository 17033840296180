/*
mixin to traverse through all available themes
and pick the map with key matching '<name of the theme>',
and render the style passed to mixin via @content using that theme
*/
@mixin themify($themes) {
  @each $theme, $map in $themes {
    .#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

// Screen Size Mixins
@mixin sm {
  @media (min-width: #{$screen-dsk-small-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-dsk-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-dsk-lg-min}) {
    @content;
  }
}

@mixin xl2 {
  @media ( min-width: #{$screen-dsk-2xl-min}) {
    @content;
  }
}
