@import "../../../sass/common/variables.scss";

.arrow-container {
  display: grid;
  place-items: center;
}

.inverted-expand-more {
  top: -2px;
  transform: rotate(180deg);
}
