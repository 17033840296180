@import "../../../../sass/common/variables.scss";

.drawer-conatiner {
  .header {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    border-bottom: 1px solid $solver-modal-header-border-color;
    .header-text {
      width: 100%;
      height: 100%;
      padding: unset;
      text-align: left;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0px;
      color: $filters-font-color;
      opacity: 1;
      display: grid;
      align-items: center;
      padding-left: 1.5vw;
    }
    .close {
      display: grid;
      place-items: center;
      .MuiSvgIcon-colorPrimary {
        color: black;
        opacity: 0.26;
      }
    }
  }
  .text-field-select-menu-container {
    .field {
      display: grid;
      place-self: normal;
      padding: 1.68vh 1vw 0vh 1vw;
      width: 50%;

      .MuiOutlinedInput-root.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: black;
        }
      }

      .MuiInputLabel-outlined {
        color: black;
      }
    }

    .select-menu {
      display: grid;
      place-self: normal;
      grid-auto-flow: column;
      padding: 3vh 1vw;

      .add {
        display: grid;
        justify-self: end;

        .MuiSvgIcon-colorPrimary {
          color: black;
          opacity: 0.26;
        }
      }

      .MuiSelect-root {
        padding: unset;
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0px;
        color: $filters-font-color;
        opacity: 1;
      }
      .MuiSelect-select {
        background-color: unset;
      }

      .MuiInputBase-input {
        padding-left: 2vw;
      }

      .MuiSelect-icon {
        left: 0;
        right: unset;
        display: grid;
        top: unset;
        color: #b4b4b4;
      }
      .MuiSelect-iconOpen {
        left: 0;
      }
    }
  }

  .cancel-submit-buttons {
    text-align: right;
    margin: 20px 30px 20px;
    #submit-button {
      background-color: $solver-range-picker-handles-color;
      text-transform: unset;
      height: 41px;
      width: 134px;
      padding: unset;
      margin-left: 10px;
      .MuiButton-label {
        color: $site-background-color;
      }
    }
    #cancel-button {
      border: 1px solid $solver-range-picker-handles-color;
      text-transform: unset;
      height: 41px;
      width: 134px;
      padding: unset;
      .MuiButton-label {
        color: $solver-range-picker-handles-color;
      }
    }
  }

  .rules-manager-container {
    display: grid;
    width: 57vw;
    height: 100%;
    grid-template-rows: 8vh 12vh auto;

    .add {
      display: grid;
      justify-self: end;
    }

    .close {
      display: grid;
      place-items: center;
    }
  }

  .body {
    padding: 1vh 2vw 0vw 2.75vw;
    height: 80vh;
    overflow-y: scroll;

    .tab-section {
      .MuiAppBar-colorPrimary {
        color: $filters-font-color;
        background-color: white;
      }

      .MuiPaper-elevation4 {
        box-shadow: unset;
      }

      .MuiTab-root {
        width: 100% !important;
      }

      .tab-rules {
        margin-top: 6vh;
      }

      .MuiTabs-flexContainer {
        display: flex;
        place-content: center;
      }

      .MuiTabs-indicator {
        background-color: $solver-range-picker-handles-color;
        transition: none;
      }
    }

    .notification {
      margin-left: 20vw;
      margin-top: -1vh;
      font-size: 14px;
      color: $filters-font-color;
    }

    .loading-main {
      padding-top: 5vh;
    }

    .header {
      border-bottom: 0px;
      padding-left: 1.5vw;
    }

    .MuiAccordianSummary-root {
      border: unset !important;
    }

    .MuiAccordian-root {
      width: 100%;
      padding-top: 1vh;
    }

    .heading {
      font-size: 16px;
      font-weight: 600;
      color: $filters-font-color;
    }

    .expansion-panel-far {
      margin-top: 2vh;
    }

    .rules-container {
      margin: 0vh 0.5vw;
      padding-bottom: 2vh;

      .MuiAccordianSummary-root {
        padding-left: 0px;
      }

      .MuiCollapse-root {
        padding: 0px 1vw;
      }
    }

    .rule-table-container {
      .rule-name {
        width: 20vw;
        padding-left: 1vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .table-header {
        display: grid;
        grid-auto-flow: column;
        font-size: 14px;
        padding-bottom: 1vh;
        padding-top: 1vh;
        border-bottom: 1px solid $solver-modal-header-border-color;
        /* TODO: commenting as CRWEB-267, uncomment when ready to implement actual functionality */
        // grid-template-columns: 1.3fr 3fr 1.5fr 2fr 2fr 1fr;
        grid-template-columns: 20vw 1.5fr 2fr 2fr;
        color: $filters-font-color;
        font-weight: 600;
        place-items: center;

        .irop-value-header {
          padding-left: 0.7vw;
        }
      }
      .table-body {
        .table-row-container {
          display: grid;
          grid-auto-flow: column;
          font-size: 12px;
          padding-bottom: 1vh;
          padding-top: 1vh;
          border-bottom: 1px solid $solver-modal-header-border-color;
          /* TODO: commenting as CRWEB-267, uncomment when ready to implement actual functionality */
          // grid-template-columns: 1.3fr 3fr 1.5fr 2fr 2fr 1fr;
          grid-template-columns: 20vw 1.5fr 2fr 2fr;
          align-items: center;
          color: $filters-font-color;
          height: 7vh;

          .irops-text-box,
          .warning-text-box {
            height: 2vw;
            appearance: none;
            color: $filters-font-color;
            border: none;
            border-width: 0;
            box-shadow: none;
          }

          .text-align-right {
            text-align: end;
          }

          .text-align-left {
            text-align: start;
          }

          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
            margin: 0 !important;
            appearance: none;
          }

          /* Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }

          .warning-text-box:focus .irops-text-box:focus {
            border: none;
            border-width: 0;
            box-shadow: none;
            outline: none !important;
            appearance: none;
          }

          input[type="number"]:focus {
            outline: none;
          }

          .value-container {
            display: grid;
            place-items: center;

            .click-here-for-values-label {
              text-align: center;
            }
          }

          .bordered {
            border: 1px solid $solver-modal-select-border-color;
            border-radius: 2px;
            display: grid;
            grid-auto-flow: column;
            justify-content: center;
          }

          .bordered:focus-within {
            border: 2px solid;
          }

          .centered {
            display: grid;
            place-items: center;
          }

          .time-seperator {
            padding-bottom: 1px;
          }

          .single-value {
            padding-left: 0.5vw;
          }

          .color-picker-container {
            .color-block-and-hex-string {
              display: grid;
              padding-top: 5px;
              place-items: center;
              .color-block {
                width: 20px;
                height: 20px;
                border-radius: 3px;
                margin-top: -3px;
                place-self: start;
                margin-left: 1px;
              }

              .hex-string {
                margin-left: 5px;
                margin-top: -1px;
              }
            }

            .sketch-picker-container {
              display: grid;
              place-items: center;
              padding: 1vh 0vw;
              position: absolute;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}

.strategy-manager-container {
  display: grid;
  width: 45vw;
  height: 100%;
  display: grid;
  width: 45vw;
  height: 100%;
  grid-template-rows: 8vh 8vh auto;
  .strategy {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    font-size: 14px;
    color: $filters-font-color;
  }
}

#rule-manager-drawer {
  .MuiPaper-root {
    margin-left: unset;
    margin-right: unset;
  }

  input:disabled {
    background: none;
  }
}

.MuiTooltip-tooltip {
  width: fit-content !important;
}

.table-a-table-b-rule-image {
  img {
    width: 500px;
  }
}

.table-a-table-b-image {
  display: grid;
  place-items: center;
  min-height: 100vh;
}

.user-profile-container {
  display: grid;
  width: 45vw;
  height: 100%;
  grid-template-rows: 8vh auto;

  .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: -10vh;
  }

  .user-profile-body {
    padding: 3vh 2vw;

    .user-profile-option-heading {
      color: $filters-font-color;
      padding-top: 1vh;
    }

    .user-profile-sub-options {
      padding: 0.25vh;
    }

    .timezone-toggle-section {
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;

      .timezone-switch {
        position: relative;
        top: 5px;
      }

      .MuiSwitch-colorSecondary.Mui-checked {
        color: $solver-range-picker-handles-color;
      }
      .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: $solver-range-picker-handles-color;
      }

      .timezone-toggle-info-text {
        font-size: 14px;
        color: $filters-font-color;
        padding: 2vh;
        .info-icon {
          margin-left: 1vh;
          .MuiSvgIcon-fontSizeSmall {
            position: relative;
            top: 2px;
            font-size: 14px;
          }
        }
      }
    }
    .timezone-select-section {
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
      align-items: center;

      .MuiMenu-paper {
        max-height: 40vh;
      }

      .MuiSelect-root {
        font-size: 12px;
        position: relative;
        margin-left: 16px;
        border-bottom: 1px solid $crew-gantt-border-color;
        min-width: 22vw;
        max-width: 22vw;
      }

      .local-timezone-select-info-text {
        font-size: 14px;
        color: $filters-font-color;
        padding-left: 2vh;
      }

      #all-timezone-select {
        min-width: 150px;
      }
    }
  }

  .error-message {
    color: $error-color;
    padding: 2vh;
  }
}

.local-time-helper-text {
  color: $filters-font-color;
  font-size: 12px;
  max-width: 250px;
}

.no-permission-info-text {
  color: $filters-font-color;
  font-size: 12px;
  max-width: 150px;
}

.reports-dropdown-main {
  display: flex;
  flex-direction: column;
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: $site-font-color;
  }
}

@include sm {
  .drawer-conatiner {
    .body {
      .rule-table-container {
        .table-body {
          .table-row-container {
            .irops-text-box,
            .warning-text-box {
              max-width: 2.7vw;
            }
            .bordered {
              width: 5.9vw;
            }
            .color-picker-container {
              .sketch-picker-container {
                margin-left: -14vw;
                margin-top: 3vh;
              }
            }
          }
        }
      }
    }
  }
}

@include md {
  .drawer-conatiner {
    .body {
      .rule-table-container {
        .table-body {
          .table-row-container {
            .irops-text-box,
            .warning-text-box {
              max-width: 2.3vw;
            }
            .bordered {
              width: 5vw;
            }
            .color-picker-container {
              .sketch-picker-container {
                margin-left: -11vw;
                margin-top: 3vh;
              }
            }
          }
        }
      }
    }
  }
}

@include xl2 {
  .drawer-conatiner {
    .body {
      .rule-table-container {
        .table-body {
          .table-row-container {
            .irops-text-box,
            .warning-text-box {
              max-width: 2.2vw;
            }
            .bordered {
              width: 5vw;
            }
            .color-picker-container {
              .color-block-and-hex-string {
                .sketch-picker-container {
                  margin-left: -12vw;
                  margin-top: 3vh;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include lg {
  .drawer-conatiner {
    .body {
      .rule-table-container {
        .table-body {
          .table-row-container {
            .irops-text-box,
            .warning-text-box {
              max-width: 2.2vw;
            }
            .bordered {
              width: 5vw;
            }
            .color-picker-container {
              .color-block-and-hex-string {
                .sketch-picker-container {
                  margin-left: -9vw;
                  margin-top: 2vh;
                }
              }
            }
          }
        }
      }
    }
  }
}
