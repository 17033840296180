@import "./mixins.scss";

//Colors
$white-color: white;
$black-color: black;
$dark-grey: #747474;
$inverted-dark-grey: #8b8b8b;
$boston-blue-color: #3b81bc;
$inverted-boston-blue-color: #c47e43;
$light-grey: #dddddd;
$inverted-light-grey: #222222;
$orangered-color: orangered;
$inverted-orangered-color: #00b2ff;

//site colors
$site-background-color: $white-color;
$site-font-color: $black-color;
$error-color: orangered;
$site-link-color: #3b81bc;
$crew-gantt-border-color: #d6d6d6;
$duty-border-color: #9f9f9f;
$standard-flight-color: #92a5b5;
$sit-time-color: #c2c2c2;
$layover-color: #d9d9d9;
$left-panel-font-color: #575962;
$filters-font-color: #707070;
$run-solver-button-color: #e39049;
$solver-range-picker-background-color: #90b7d9;
$solver-range-picker-handles-color: #2f71ab;
$reserve-duty-background-color: #f5f5f5;
$reserve-duty-font-color: #727272;
$solver-modal-select-border-color: #c8c8c8;
$solver-modal-header-border-color: #caccd1;
$open-flight-position-triangle: #f7090970;
$filter-seperator: #ebedf2;
$filter-clear-button-color: #b4b4b4;
$site-warning-color: rgb(238, 238, 8);
$solver-scenario-in-progress-fill-color: #ffcc00;
$solver-scenario-complete-fill-color: green;
$report-black-75-percent-color: rgba($color: #000000, $alpha: 0.75);
$border-bottom-divider-gray-color: rgba($color: #000000, $alpha: 0.05);
$report-background-color: repeating-linear-gradient(120deg, #f3f7fd, white 1px);

// Screen Sizes
$screen-dsk-small-min: 1280px;
$screen-dsk-md-min: 1440px;
$screen-dsk-2xl-min: 1536px;
$screen-dsk-lg-min: 1920px;

.display-none {
  display: none;
}

.display-block {
  display: block;
}

// all available themes
$themes: (
  dark: (
    backgroundColor: $black-color,
    textColor: $white-color,
    buttonBackgroundColor: $inverted-boston-blue-color,
    disclaimerTextColor: $inverted-dark-grey,
    errorColor: $inverted-orangered-color,
    borderColor: $inverted-light-grey
  ),
  light: (
    backgroundColor: $white-color,
    textColor: $black-color,
    buttonBackgroundColor: $boston-blue-color,
    disclaimerTextColor: $dark-grey,
    errorColor: $orangered-color,
    borderColor: $light-grey
  )
);
