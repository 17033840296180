@import "../../../sass/common/variables.scss";

.disp-summary-main-header {
  display: grid;
  min-height: 75px;
  border-bottom: 1px solid $border-bottom-divider-gray-color;

  img {
    justify-content: center;
    height: 37px;
    width: 93px;
    margin: auto 15px;
  }

  .dashboard-summary {
    // justify-content: center;
    margin: auto 2vw;
    grid-template-columns: 50% 50% 20%;
    display: grid;
    padding-left: 1vw;

    .button-group-label {
      padding: 0 15px;

      .MuiSwitch-colorSecondary.Mui-checked {
        color: $solver-range-picker-handles-color;
      }

      .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: $solver-range-picker-handles-color;
      }
    }

    .text-abbreviated {
      display: inline-block;
    }
    .text-full {
      display: none;
    }
    .MuiToggleButtonGroup-root {
      background-color: transparent;
    }

    .MuiToggleButton-root {
      text-transform: none;
      white-space: nowrap;
      border: 1px solid rgba(0, 0, 0, 0.23);
      color: rgba(0, 0, 0, 0.87);

      &.Mui-selected {
        color: $solver-range-picker-handles-color;
      }
    }

    .MuiToggleButtonGroup-grouped:not(:first-child) {
      margin-left: 0px;
    }

    .MuiToggleButton-sizeSmall {
      height: 4.5vh;
      padding: 3px 9px;
      font-size: 0.625rem;
      width: 4.5vw;
    }
  }

  .run-solver-with-logout {
    margin: auto auto;
    height: 41px;
    display: grid;
    grid-auto-flow: column;

    .run-solver-button-container {
      margin: auto auto;

      .Mui-disabled {
        background-color: rgba(0, 0, 0, 0.25) !important;
      }

      #run-solver-button {
        background-color: $run-solver-button-color;
        height: 41px;
        color: $site-background-color;

        .MuiButton-label {
          font-size: 12px;
        }
      }
    }

    .hamburger-menu-container {
      margin: auto auto;
      display: grid;
      justify-items: start;
      align-items: end;
    }

    .logout {
      margin-left: 20px;

      .MuiSvgIcon-root {
        position: relative;
        top: 15px;
      }
    }
  }
}

.disp-summary-main {
  width: 100%;
  top: 0;
  z-index: 20;
  padding: 5px 20px;
  border-bottom: 1px solid $crew-gantt-border-color;
  color: $filters-font-color;
  background-color: $site-background-color;
  padding: 0.5vh 2vw 1vh 2vw;
  .filter-container {
    display: grid;
    cursor: pointer;
    height: 100%;
    .filter-top-section {
      height: 100%;
    }
    .filter-drop-down-section {
      transition: all 0.25s;
    }
  }

  .all-filters-main {
    display: flex;
    justify-content: space-between;

    .filter-options {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 1vw;
      align-content: end;

      .select-menus {
        position: relative;
        top: 13px;
      }

      .crew-name-search-container {
        position: relative;
        top: 2px;

        .MuiFormLabel-root {
          font-size: 12px;
          color: rgba($color: #000000, $alpha: 0.8);
        }

        .MuiFormLabel-root.Mui-focused {
          color: $filters-font-color;
        }

        .MuiInputBase-input {
          font-size: 12px;
        }

        .MuiTextField-root {
          width: 100px;
        }

        .MuiInput-underline:before {
          border-bottom: 1px solid $crew-gantt-border-color;
        }

        .MuiInput-underline:after {
          border-bottom: 1px solid black;
        }

        .MuiSvgIcon-root {
          font-size: 24px;
          padding-bottom: 6px;
        }

        .MuiInputLabel-shrink {
          transform: translate(0, 3.5px) scale(0.75) !important;
        }

        .MuiInputLabel-formControl {
          transform: translate(0, 20px) scale(1) !important;
        }

        .MuiInputLabel-shrink {
          transform: translate(0, 3.5px) scale(0.75) !important;
        }
      }
    }
    .MuiSelect-root {
      font-size: 12px;
      position: relative;
      margin-left: 16px;
      border-bottom: 1px solid $crew-gantt-border-color;
      min-width: 100px;
      max-width: 100px;
    }

    .search-icon {
      position: relative;
      top: 8px;
      left: -25px;
    }
    .flightops-inflight-filter {
      display: grid;
      grid-auto-flow: column;
      margin-top: 16px;
      .MuiTypography-body1 {
        font-size: 12px;
        color: $filters-font-color;
      }
      .MuiFormControlLabel-root {
        margin-left: unset;
        margin-right: unset;
      }
    }

    .filter-drop-down-entry {
      display: grid;
      grid-auto-flow: column;
      font-size: 12px;
      position: relative;
      min-width: 100px;
      justify-content: space-between;
      align-content: center;
      max-width: 100px;
      grid-template-columns: 100px auto;
      bottom: -11px;

      .filter-heading {
        color: rgba($color: #000000, $alpha: 0.8);
        border-bottom: 1px solid #d6d6d6;
        padding-bottom: 8px;
      }

      .filter-arrow-icon {
        position: relative;
        bottom: 1px;
        margin-left: -1.3vw;
      }
    }
  }
}

.publish-solver-dialog-conatiner {
  display: grid;

  .publish-heading {
    width: 100%;
    text-align: left;
    padding-left: 23px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 2px solid $solver-modal-header-border-color;
    font-size: 18px;
    color: $filters-font-color;
  }
  .publish-info-main {
    border-bottom: 2px solid $solver-modal-header-border-color;
    display: grid;

    .details {
      padding: 20px 40px 20px 30px;
      .scenario-name {
        font-size: 18px;
        color: $filters-font-color;
        word-break: break-word;
      }

      .MuiTableCell-body {
        color: $filters-font-color;
      }

      .input-output-heading {
        font-size: 16px;
        color: $filters-font-color;
        word-break: break-word;
        padding-top: 10px;
      }

      .scenario-input-kpis {
        border-bottom: 1px solid $solver-modal-header-border-color;
        color: $left-panel-font-color;
        padding: 10px;

        .metric-container {
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          .metric-name {
            font-size: 15px;
            grid-column: 2 / span 8;
          }
          .metric-input-value {
            font-size: 15px;
            justify-self: center;
            grid-column: 6 / span 2;
          }
          .metric-output-value {
            font-size: 15px;
            justify-self: center;
          }
          .input-heading {
            font-size: 13px;
          }
        }
      }
      .scenario-output-kpis {
        color: $left-panel-font-color;
        padding: 10px;
        padding-right: 27px;
        .metric-container {
          display: flex;
          justify-content: space-between;
          .metric-name {
            font-size: 13px;
          }
          .metric-value {
            font-size: 15px;
          }
        }
      }
    }
    .links {
      font-size: 13px;
      padding: 20px 0 20px 20px;
      .link {
        margin-bottom: 10px;
      }
    }
  }
  .publish-action-buttons {
    text-align: right;
    margin: 15px 30px 15px;
    #publish-button {
      background-color: $solver-range-picker-handles-color;
      text-transform: unset;
      height: 41px;
      width: 134px;
      padding: unset;
      margin-left: 10px;
      .MuiButton-label {
        color: $site-background-color;
      }
    }
    #cancel-button {
      border: 1px solid $solver-range-picker-handles-color;
      text-transform: unset;
      height: 41px;
      width: 134px;
      padding: unset;
      .MuiButton-label {
        color: $solver-range-picker-handles-color;
      }
    }
  }
}

#run-solver-dialogue {
  .run-solver-dialogue-contents {
    height: 90vh;

    .MuiTabs-root {
      padding-left: 5%;
      .MuiTabs-indicator {
        background-color: $solver-range-picker-handles-color;
      }
    }
    .solver-crew-info {
      padding: 5% 10% 0 10%;
      max-height: 70vh;

      .crew-row {
        display: grid;
        grid-auto-flow: column;
        justify-content: start;
        margin-bottom: 3%;
        border-bottom: 1px solid $solver-modal-header-border-color;
        padding-left: 2%;
        color: $filters-font-color;

        .custom-check-box-container {
          position: relative;
          top: -5px;
        }
      }
    }

    .run-solver-dialogue-header {
      width: 100%;
      text-align: left;
      padding-left: 23px;
      padding-top: 2vh;
      padding-bottom: 2vh;
      border-bottom: 1px solid $solver-modal-header-border-color;
      font-size: 18px;
      color: $filters-font-color;
    }
    .select-option-heading {
      font-size: 18px;
      color: $filters-font-color;
      text-align: left;
      margin-bottom: 0.5vh;
    }

    .scenario-name {
      margin-bottom: -0.1vh;
    }

    .option {
      padding: 0 60px;
      width: 100%;
      max-height: 15vh;
    }

    .select-rule-set {
      padding-top: 3vh;
    }

    .scenario-name {
      margin-top: 3vh;
    }

    .select-time-frame {
      padding-top: 3vh;
    }

    .MuiInputBase-root {
      width: 100%;
    }

    .to-from-heading {
      display: grid;
      grid-template-columns: 1fr 1fr;
      color: $filters-font-color;
      grid-gap: 30px;
      font-size: 14px;
    }

    .to-from {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;

      .from-date {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        grid-gap: 10px;

        .MuiInputBase-root {
          border: 1px solid $solver-modal-header-border-color;
          padding-left: 10px;
          color: $filters-font-color;
        }

        .heading {
          font-size: 10px;
          color: $filters-font-color;
        }

        .date-div {
          position: relative;
          padding: 5px;
          border: 1px solid $solver-modal-header-border-color;
          color: $filters-font-color;

          .calendar-div {
            position: absolute;
            border: 1px solid $solver-modal-header-border-color;
            background: $reserve-duty-background-color;
            width: 250px;
            z-index: 10;
            top: 0;
          }
        }
      }
    }

    .fix-open-flights-checkbox {
      padding-top: 5vh;
      display: grid;
      grid-auto-flow: column;
      place-items: center;
      color: $filters-font-color;
      justify-content: flex-start;
      padding-left: 49px;
    }

    #rule-set-select {
      .MuiSelect-select.MuiSelect-select {
        border: 1px solid $solver-modal-select-border-color;
        height: 4vh;
      }
      .MuiInputBase-input {
        font-size: 14px;
        height: 17px;
        color: $filters-font-color;
        padding: unset;
        padding-left: 15px;
        padding-top: 10px;
      }
      .MuiSelect-icon {
        padding-right: 10px;
      }
      .MuiTextField-root .MuiOutlinedInput-root {
        height: 50px;
        border-radius: 0;
      }
    }

    .run-solver-dialogue-footer {
      padding-left: 40%;
      padding-top: 10%;
      padding-right: 10%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 30px;
      #run-solver-dialogue-button {
        background-color: $solver-range-picker-handles-color;
        text-transform: unset;
        height: 41px;
        padding: unset;
        margin-left: 10%;
        .MuiButton-label {
          color: $site-background-color;
        }
      }
      #run-solver-dialogue-cancel-button {
        border: 1px solid $solver-range-picker-handles-color;
        text-transform: unset;
        height: 41px;
        padding: unset;
        .MuiButton-label {
          color: $solver-range-picker-handles-color;
        }
      }
    }
  }
  .MuiDialog-paper {
    min-width: 500px;
    min-height: 375px;
    overflow: visible;
  }
  .MuiPaper-rounded {
    border-radius: 1;
  }
}

.MuiAutocomplete-option {
  padding-top: unset !important;
  padding-left: unset !important;
  padding-right: unset !important;
  padding-bottom: unset !important;
  grid-column-gap: unset !important;
  column-gap: unset !important;
}

.auto-complete-loading-placeholder {
  padding: 8px 16px;
}

.right-side-menu {
  width: 20vw;
  .right-side-menu-body {
    padding-top: 1vh;

    .MuiAccordionSummary-root.Mui-expanded {
      min-height: unset;
    }

    .MuiAccordionSummary-root {
      padding: 0;
      min-height: unset;
    }

    .MuiAccordion-root.Mui-expanded {
      margin: 0;
    }

    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0px;
      padding: 6px 16px;
    }

    .MuiAccordionSummary-content {
      margin: 0px;
    }
    .MuiAccordionSummary-content {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.87);
    }

    .MuiAccordionDetails-root {
      display: flex;
      padding: 0px 16px 0px;
    }

    .MuiPaper-elevation1 {
      box-shadow: unset;
    }

    .MuiMenuItem-root {
      width: 100%;
    }
  }
}

.react-calendar__navigation {
  margin-bottom: unset;
}

.react-calendar__tile {
  padding: 0.5em 0.5em !important;
}

@media screen and (max-height: 650px) {
  .run-solver-dialogue-footer {
    padding-top: 5% !important;
  }

  .calendar-div {
    top: -10vh !important;
  }
}

@media screen and (max-height: 600px) {
  .scenario-name {
    margin-top: 2vh;
  }

  .run-solver-dialogue-footer {
    padding-top: 2vh !important;
  }

  .fix-open-flights-checkbox {
    padding-top: 6vh !important;
  }

  .calendar-div {
    top: -15vh !important;
  }
}

@include sm {
  .disp-summary-main-header {
    grid-template-columns: 190px 58vw 13.5vw 15vw;

    .dashboard-summary {
      margin: auto 0vw;
      grid-template-columns: 47% 48% 16%;
      .MuiToggleButton-sizeSmall {
        height: 4.5vh;
        padding: 3px 9px;
        font-size: 0.625rem;
        width: 4.4vw;
      }

      .button-group-label {
        padding: 0;
        .MuiSkeleton-root {
          width: 350px;
        }
      }
    }
  }

  .disp-summary-main-share-header {
    grid-template-columns: 150px 69vw 4vw 17vw;
  }

  .publish-solver-dialog-conatiner {
    .publish-info-main {
      .details {
        .scenario-input-kpis {
          .solver-input-kpi-table {
            min-width: 35vw;
          }
        }
      }
    }
  }
}

@include md {
  .disp-summary-main-header {
    grid-template-columns: 150px 60vw 13vw 17vw;
    .dashboard-summary {
      margin: auto 3vw auto 0vw;
      grid-template-columns: 50% 50% 20%;
      .button-group-label {
        padding: 0 15px;
        .MuiSkeleton-root {
          width: 350px;
        }
      }
    }
  }

  .disp-summary-main-share-header {
    grid-template-columns: 150px 69vw 4vw 17vw;
  }

  .publish-solver-dialog-conatiner {
    .publish-info-main {
      .details {
        .scenario-input-kpis {
          .solver-input-kpi-table {
            min-width: 35vw;
          }
        }
      }
    }
  }
}

@include xl2 {
  .disp-summary-main-header {
    grid-template-columns: 200px 62vw 13vw 13vw;
    .dashboard-summary {
      margin: auto auto;
      grid-template-columns: 45% 45% 15%;
      .button-group-label {
        padding: 0 15px;
        .MuiSkeleton-root {
          width: 400px;
        }
      }
    }
  }

  .disp-summary-main-share-header {
    grid-template-columns: 200px 69vw 4vw 16vw;
  }

  .publish-solver-dialog-conatiner {
    .publish-info-main {
      .details {
        .scenario-input-kpis {
          .solver-input-kpi-table {
            min-width: 29vw;
          }
        }
      }
    }
  }
}

@include lg {
  .disp-summary-main-header {
    grid-template-columns: 250px auto 13vw 13vw;
    .dashboard-summary {
      margin: auto 0vw;
      grid-template-columns: 50% 50% 15%;
    }
  }

  .disp-summary-main-share-header {
    grid-template-columns: 250px auto 3vw 15vw;
  }

  .publish-solver-dialog-conatiner {
    .publish-info-main {
      .details {
        .scenario-input-kpis {
          .solver-input-kpi-table {
            min-width: 26vw;
          }
        }
      }
    }
  }
}
