.grid {
  display: grid;
}

.orient-horizontally {
  display: grid;
  grid-auto-flow: column;
}

.place-items-center {
  display: grid;
  place-items: center;
}

.align-items-center {
  align-items: center;
}

.justify-content-right {
  justify-content: right;
}

.no-bottom-border {
  border-bottom: unset !important;
}

.bold {
  font-weight: bolder !important;
}

.font-size-15 {
  font-size: 15px !important;
}
