@import "../sass/common/variables.scss";

#app-container {
  background-color: $white-color;
  color: $black-color;

  max-width: 2000px; //Website's max width
  margin: 0 auto;

  .app-loader {
    display: grid;

    background-color: $white-color;

    width: 100vw;
    height: 100vh;

    place-items: center;
  }
}
