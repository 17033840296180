.menu-item-with-ellipsis-container {
  width: 100%;
  display: grid;
  justify-content: space-between;
  grid-auto-flow: column;

  .MuiButton-root {
    min-width: unset;
  }
}
