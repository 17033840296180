@import "./variables.scss";
@import "./mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");

//Text Selections
::selection {
}

//Anchor Tags
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  transition: color 0.3s ease;
  color: $site-link-color;
}

a:hover {
  text-decoration: none;
  transition: color 0.3s ease;
  cursor: pointer;
}

//Font Size
html {
  font-size: 16px;
  font-family: "Roboto", sans-serif;

  color: $site-font-color;
  background-color: $site-background-color;

  max-width: 100vw;
  overflow: hidden;

  max-height: 100vh;
}
