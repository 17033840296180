@import "../../../sass/common/variables.scss";

.violation-message-pairing-info-container {
  display: grid;
  width: 585px;
  color: $site-font-color;
  grid-gap: 0.5vh;

  .MuiPaper-elevation1 {
    box-shadow: unset;
  }

  .MuiAccordionSummary-root {
    width: 100%;
  }

  .MuiAccordionSummary-root {
    padding: 0px 16px;
  }

  .MuiAccordion-root.Mui-expanded {
    margin: unset;
  }

  .MuiAccordionSummary-content {
    font-size: 18px;
    margin: 0;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    .violation-message-header {
      .violations-label {
        position: relative;
        top: -4px;
      }
      .resolve-button-container {
        .resolve-button {
          position: relative;
          top: -6px;
        }
      }
    }
    margin-bottom: 0;
  }

  .violation-message-header {
    display: grid;
    grid-auto-flow: column;
    column-gap: 0.2vw;
    width: 100%;

    .MuiIconButton-root {
      padding: 0px 8px;
      border-radius: 5px;
    }

    .comment-count {
      position: absolute;
      color: white;
      font-size: 11px;
      font-weight: 700;
      top: 3px;
    }

    .resolve-button-container {
      justify-self: end;

      .resolve-button {
        color: $site-link-color;
        border: 1px solid $site-link-color;
      }
    }
  }
}

.MuiTooltip-tooltip {
  max-width: unset !important;
}

.pairing-info-header {
  .MuiButton-root {
    min-width: unset;
    margin-top: -2vh;
    padding: 0 0 0 0.2vw;
  }
  .MuiSvgIcon-root {
    width: 12px;
  }
}
