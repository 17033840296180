@import "../../sass/common/variables.scss";

// TODO: Temporary styling added as of now. Styling needs to be updated later for this component

.error-boundary-preserve-space {
  white-space: pre-wrap;
}

.error-boundary-error {
  color: $white-color;
  background-color: $black-color;
}
