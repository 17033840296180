@import "../../../sass/common/variables.scss";

.pairing-info-container {
  width: 585px;
  max-height: 200px;
  overflow-y: auto;

  .header {
    display: grid;
    grid-auto-flow: column;
    font-size: 14px;
    place-items: center;
    grid-template-columns: 155px 177px 185px 64px;
  }
  .duty-period-container {
    padding: 1vh 1vh;

    .duty-period-unexpanded {
      display: grid;
      place-items: center;
      grid-auto-flow: column;
      width: 100%;
      grid-template-columns: 150px 174px 183px 64px;

      div {
        font-size: 13px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
      }

      .duty-report-release-block {
        span {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  .no-pairing-info {
    padding: 10px 22px;
    font-size: 14px;
  }

  .pairing-info-loading {
    padding: 10px 22px;
    font-size: 14px;
    display: grid;
    place-items: center;
    .no-pairing-info {
      padding: 3px 22px;
      font-size: 12px;
    }
  }
  .MuiCircularProgress-root {
    width: 15px !important;
    height: 15px !important;
  }
}
