@import "../../../../sass/common/variables.scss";

.solver-run-info {
  color: $filters-font-color;
  font-size: 14px;
  grid-column-gap: 1vw;
  padding-right: 1vw;

  .solver-run-name-section {
    padding-top: 5px;
  }

  .solver-run-status-section {
    grid-column-gap: 0.5vw;
    justify-content: flex-start;
    padding-top: 5px;
  }

  .solver-run-error-icon {
    fill: $orangered-color;
  }

  .solver-run-completed-icon {
    fill: $solver-scenario-complete-fill-color;
  }

  .solver-run-in-progress-icon {
    fill: $solver-scenario-in-progress-fill-color;
    font-size: 16px;
  }

  .share-button {
    .MuiIconButton-root {
      padding: 5px;
      margin-right: -1px;
      padding-top: 3px;
    }
    .MuiSvgIcon-root {
      font-size: 20px;
    }
  }

  .share-url-copied {
    .MuiSvgIcon-root {
      color: $solver-range-picker-handles-color;
    }
  }
}
