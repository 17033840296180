@import "../../../sass/common/variables.scss";

.custom-check-box-container {
  .MuiSvgIcon-root {
    width: 1.11vw !important;
    height: 1.11vw !important;
  }
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: $solver-range-picker-handles-color;
  }
}
