@import "../../../../sass/common/variables.scss";

.range-picker-container {
  position: relative;
  margin: 0;
  padding: 0;
  display: grid;
  place-items: center;
  height: 1.2vh;
  width: 100%;
  padding-top: 1px;
  .multi-range-selector {
    position: relative;
    height: 100%;
    width: 100%;
    border-top: 0.01vw solid $crew-gantt-border-color;
    input[type="range"] {
      position: absolute;
      width: 100%;
      margin: 0;
      background: transparent;
      pointer-events: none;
      z-index: 3;
      opacity: 0;
    }

    input[type="range"]::-webkit-slider-thumb {
      pointer-events: all;
      width: 10px;
      height: 12px;
      opacity: 0.5;
      cursor: grab;
    }

    input[type="range"]::-moz-range-thumb {
      pointer-events: all;
      width: 10px;
      height: 12px;
      opacity: 0.5;
      -webkit-appearance: none;
      appearance: none;
      cursor: grab;
    }

    .set-selector {
      input[type="range"] {
        pointer-events: all;
        z-index: 2;
      }
    }
  }

  .timeline-slider {
    position: relative;
    height: 100%;
    width: 100%;
    .range {
      position: absolute;
      height: 1.6vh;
      background-color: $solver-range-picker-background-color;
      opacity: 0.32;
      z-index: 2;
      left: 25%;
      right: 25%;
    }
    .picker {
      position: absolute;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      opacity: 1;
    }

    .picker.left {
      border-left: 6px solid $solver-range-picker-handles-color;
      left: 25%;
    }

    .picker.right {
      border-right: 6px solid $solver-range-picker-handles-color;
      right: 25%;
    }

    .gantt-slider {
      position: relative;
      width: 100%;
      top: 11px;
      .gantt-range {
        position: absolute;
        height: 100vh;
        background-color: $solver-range-picker-background-color;
        opacity: 0.1;
      }
      .gantt-picker {
        position: absolute;
        min-width: 1px;
        max-width: 1px;
        height: 100vh;
        background-color: $solver-range-picker-handles-color;
        opacity: 0.5;
      }
    }
  }
}
