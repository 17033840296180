@import "../../common/variables.scss";

.material-ui-select-for-duration-selection-root {
  padding: unset;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  color: $left-panel-font-color;
  opacity: 1;
}
.material-ui-select-for-duration-selection-icon {
  left: 0;
  right: unset;
  display: grid;
  top: unset;
  color: #b4b4b4;
}
.material-ui-select-for-duration-selection-iconOpen {
  left: 0;
}

.material-ui-select-for-duration-selection:focus {
  background-color: unset;
}
