@import "../../../sass/common/variables.scss";

.textfield-filter-container {
  position: relative;
  top: -3px;

  .MuiFormLabel-root {
    font-size: 12px;
    color: rgba($color: #000000, $alpha: 0.8);
  }

  .MuiFormLabel-root.Mui-focused {
    color: $filters-font-color;
  }

  .MuiInputBase-input {
    font-size: 12px;
  }

  .MuiTextField-root {
    width: 100px;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid $crew-gantt-border-color;
  }

  .MuiInput-underline:after {
    border-bottom: 1px solid black;
  }

  .MuiSvgIcon-root {
    font-size: 24px;
    padding-bottom: 6px;
  }

  .MuiInputLabel-shrink {
    transform: translate(0, 3.5px) scale(0.75) !important;
  }

  .MuiInputLabel-formControl {
    transform: translate(0, 20px) scale(1) !important;
  }

  .MuiInputLabel-shrink {
    transform: translate(0, 3.5px) scale(0.75) !important;
  }
}
