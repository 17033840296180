@import "../../../../sass/common/variables.scss";

.all-disp-container {
  position: absolute;
  top: 19vh; /** based on page ruling the mockups, the disruption summary and timeline take up 19% of the view height initially **/
  z-index: 2;
  scroll-behavior: smooth;

  .all-disp-main {
    overflow-x: scroll;
    width: 84vw; /** based on page ruling the mockups, right area for all gantts takes 84% of view width **/
    margin-left: 16vw; /** based on page ruling the mockups, left area for crew selection takes 16% of view width **/
    height: 81vh;
    scroll-behavior: smooth;

    .gantt-tooltip,
    .gantt-tooltip-pairing {
      background: $white-color 0% 0% no-repeat padding-box;
      border: none;
      box-shadow: 0px 2px 14px #00000073;
      opacity: 1;
      width: 300px;
    }

    .gantt-tooltip-pairing {
      margin-top: 49px;
    }

    .tooltip-violation-container {
      display: grid;
      place-items: center;
    }
    .tooltip-violation-message {
      max-width: 100%;
      text-align: center;
      word-break: break-word;
    }
    .tooltip-resolve-button {
      position: relative;
      height: 30px;
      width: 150px;
      margin-left: 4px;
      margin-top: 4px;
      background: $white-color 0% 0% no-repeat padding-box;
      color: $solver-range-picker-handles-color;
      border: 1px solid $solver-range-picker-handles-color;
      border-radius: 4px;
      opacity: 1;
      cursor: pointer;
      user-select: none;
      z-index: 99;
    }
    .tooltip-resolve-button:focus {
      outline: none;
    }

    .all-disp-left-panel {
      height: 79vh;
      overflow: hidden;
      width: 16vw;
      position: absolute;
      left: 0%;
      border-right: 3px solid $crew-gantt-border-color;

      .open-flights {
        border-bottom: 2px solid $crew-gantt-border-color;

        .crew-rank {
          position: relative;
          top: 4px;
        }
      }

      .left-side-row {
        height: 51px;
        font-size: 12px;
        border: 0.5px solid $crew-gantt-border-color;
        border-right: 0;

        .crew-info {
          padding: 10px 5px;
          display: flex;
          font-size: 14px;
          color: $left-panel-font-color;

          .crew-checkbox {
            position: relative;
            top: -2px;
          }
          .crew-rank {
            margin-right: 10px;
            position: relative;
            top: 5px;
          }
          .crew-name {
            position: relative;
            top: 5px;
          }
        }
      }
    }

    .all-disp-right-gantt-container {
      .loading-main .card-skeleton {
        text-align: left;
        margin-left: 50vw;
      }
      .open-flights {
        border-bottom: 2px solid $crew-gantt-border-color;
        .open-flight-container {
          position: relative;
        }
      }
      .right-side-row {
        display: flex;
        font-size: 11px;
        height: 51px;
        padding: 5px 0;
        border: 0.5px solid $crew-gantt-border-color;
        border-left: 0;

        .gantt-row {
          margin: 3px;
          margin-left: 20px;
        }
      }
    }
  }
  .MuiExpansionPanelSummary-root {
    border-top: 1px solid $crew-gantt-border-color;
  }
  .MuiExpansionPanelSummary-content {
    font-size: 15px;
    color: $left-panel-font-color;
  }
  .MuiPaper-root {
    margin-right: 1px;
    margin-left: -1px;
  }
  .MuiExpansionPanel-rounded:last-child {
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }

  .MuiExpansionPanel-rounded:first-child {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
  }
}
