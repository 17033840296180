@import "../../../../../sass/common/variables.scss";

$border-radius-for-open-times: 15px;

// TODO: border colors are assigned temporarily, will change once we move to alerting based colors
.pairing-main {
  position: relative;
  font-family: "Open Sans", sans-serif;

  .wait-time-info {
    margin-top: 8px;
  }

  .layover-main {
    display: grid;
    place-items: center;
    color: $black-color;
    position: absolute;
    background-color: $layover-color;
    height: 41px;
  }

  .duty-main {
    border: 2px solid $duty-border-color;
    height: 41px;
    font-size: 10px;
    position: absolute;
    background-color: $sit-time-color;

    .reserve-duty {
      display: grid;
      place-items: center;
      background-color: $reserve-duty-background-color;
      color: $reserve-duty-font-color;
      height: 37px;

      span {
        display: grid;
        place-items: center;
        grid-auto-flow: column;
        .MuiSvgIcon-root {
          width: 18px;
        }
      }
    }

    .time-info-main {
        position: absolute;
        bottom: 2px;
        width: 100%;
        height: 10px;
        display: inline-flex;
        justify-content: space-between;  
        font-size: 10px;
        font-weight: 400;
        padding: 0 2px;
    }

    // text color class for TimeInfo component
    .reserve-duty-text-color {
      color: $reserve-duty-font-color;
    }

    .duty-warning-border {
      background: none;
      border: 2px solid $site-warning-color;
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      pointer-events: none;
      z-index: 3;
    }

    .sit-time-main {
      position: absolute;
      display: grid;
      place-items: center;
      height: 37px;

      .unplanned-diversion-sit-time {
        display: grid;
        place-items: center;

        .MuiSvgIcon-root {
          font-size: 20px;
          transform: rotate(90deg);
        }
        .time-info {
          position: relative;
          top: -3px;
        }
      }
    }

    .flight-main {
      color: $white-color;
      padding: 2px 0;
      position: absolute;
      background-color: $standard-flight-color;
      height: 37px;

      .source-destination-info {
        display: flex;
        height: 10px;
        justify-content: space-between;
        font-size: 10px;
        position: relative;
        top: -3px;
        font-weight: 400;
        padding: 0 2px;
      }

      .flight-number {
        font-size: 12px;
        height: 13px;
        display: grid;
        justify-items: center;
        position: relative;
        top: -1px;

        .MuiSvgIcon-root {
          width: 20px;
          position: relative;
          top: -4px;
        }
      }

      .timeline-window-two-days {
        font-size: 11px;
        top: 8px;
      }
      .time-info {
        display: flex;
        height: 10px;
        justify-content: space-between;
        font-size: 10px;
        position: relative;
        top: -1px;
        font-weight: 400;
        padding: 0 2px;
      }
      .triangle-top-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: $border-radius-for-open-times solid
          $open-flight-position-triangle;
        border-right: $border-radius-for-open-times solid transparent;
      }

      .triangle-top-right {
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-top: $border-radius-for-open-times solid
          $open-flight-position-triangle;
        border-left: $border-radius-for-open-times solid transparent;
      }

      .triangle-bottom-left {
        position: absolute;
        top: 58%;
        left: 0;
        width: 0;
        height: 0;
        border-bottom: $border-radius-for-open-times solid
          $open-flight-position-triangle;
        border-right: $border-radius-for-open-times solid transparent;
      }

      .triangle-bottom-right {
        position: absolute;
        top: 58%;
        right: 0;
        width: 0;
        height: 0;
        border-bottom: $border-radius-for-open-times solid
          $open-flight-position-triangle;
        border-left: $border-radius-for-open-times solid transparent;
      }

      .rtg-text-block {
        font-size: 9px;
        font-weight: 900;
      }

      .no-margin-top {
        margin-top: 0;
      }

      .added-margin-top {
        margin-top: -1.5vh;
      }
    }

    .entity-violation {
      background-color: #ffb347;
    }
  }

  .solver-border {
    border-top: 2px dashed $solver-range-picker-handles-color !important;
    border-bottom: 2px dashed $solver-range-picker-handles-color !important;
  }

  .solver-border-left {
    border-left: 2px dashed $solver-range-picker-handles-color !important;
    border-right: none;
  }

  .solver-border-right {
    border-right: 2px dashed $solver-range-picker-handles-color !important;
    border-left: none;
  }

  .shell {
    display: grid;
    place-items: center;
    background-color: $reserve-duty-background-color;
    color: $reserve-duty-font-color;
    height: 37px;
  }

  .rest-main {
    display: grid;
    place-items: center;
    color: $black-color;
    position: absolute;
    height: 41px;
  }

  .flight-with-same-arrival-and-departure-times {
    background-color: grey !important;
  }
}

.simple-tooltip-message {
  color: $filters-font-color;
  font-size: 12px;
  max-width: 150px;
}

.violation-comment-marker,
.violation-comment-marker-flight,
.violation-comment-marker-wait-time,
.violation-comment-marker-wait-time-warning {
  position: absolute;
  left: 0px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;

  .parent-marker {
    position: absolute;
    left: -15px;
    top: -2px;
  }

  .current-marker {
    position: absolute;
    top: -13px;
  }

  .MuiIconButton-root {
    padding: 1px;
  }

  .MuiSvgIcon-root {
    fill: black;
    width: 12px;
    height: 12px;
  }
}

.violation-comment-marker-flight {
  top: 0;
}

.violation-comment-marker-wait-time-warning {
  .current-marker {
    top: -15px;
  }
}
