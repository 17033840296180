@import "../../../sass/common/variables.scss";

.confirm-dialog-container {
  height: 30vh;
  width: 30vw;
  display: grid;
  grid-template-rows: 8vh auto auto;
  place-items: center;

  .header {
    width: 100%;
    height: 100%;
    padding: unset;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    color: $filters-font-color;
    opacity: 1;
    display: grid;
    align-items: center;
    padding-left: 1.5vw;
    border-bottom: 1px solid $solver-modal-header-border-color;
  }

  .body {
    width: 100%;
    height: 100%;
    padding: unset;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: $filters-font-color;
    opacity: 1;
    display: grid;
    align-items: center;
    padding-left: 1.5vw;
  }

  .footer {
    margin-left: 8vw;
    #confirm-button {
      background-color: $solver-range-picker-handles-color;
      text-transform: unset;
      height: 41px;
      width: 134px;
      padding: unset;
      margin-left: 10px;
      .MuiButton-label {
        color: $site-background-color;
      }
    }
    #cancel-button {
      border: 1px solid $solver-range-picker-handles-color;
      text-transform: unset;
      height: 41px;
      width: 134px;
      padding: unset;
      .MuiButton-label {
        color: $solver-range-picker-handles-color;
      }
    }
    .is-alert {
      margin-left: 9vw !important;
    }
  }
}
