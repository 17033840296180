@import "../../../../../../sass/common/variables.scss";

.solver-crew-info {
  max-height: 60vh;
  padding-top: 3vh;
  padding-left: 1vw;
  padding-right: 1vw;
  overflow: auto;

  .crew-row {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    margin-bottom: 3%;
    border-bottom: 1px solid $solver-modal-header-border-color;
    padding-left: 2%;
    color: $filters-font-color;

    .custom-check-box-container {
      position: relative;
      top: -5px;
    }
  }
}
