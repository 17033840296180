@import "../../../../../sass/common/variables.scss";

#run-solver-dialog {
  display: grid;
  place-items: center;

  .MuiDialog-paperWidthSm {
    min-width: 600px;
  }

  .MuiDialog-paper {
    display: grid;
    grid-template-rows: repeat(50, 1fr);
  }

  .MuiTabs-root {
    .MuiTabs-indicator {
      background-color: $solver-range-picker-handles-color;
    }
  }

  .run-solver-dialog-header {
    grid-row: 2 / span 3;

    display: grid;
    grid-template-columns: repeat(30, 1fr);

    border-bottom: 1px solid $solver-modal-header-border-color;
    font-size: 18px;
    color: $filters-font-color;

    .title {
      grid-column: 2 / span 7;
    }
  }

  .run-solver-dialog-body {
    padding: 0 1vw;
    grid-row: 5 / span 41;
  }

  .run-solver-dialog-footer {
    grid-row: 46 / span 3;

    display: grid;
    grid-template-columns: repeat(40, 1fr);
    grid-column-gap: 0.5vw;

    #run-solver-dialog-cancel-button {
      grid-column: 18 / span 10;

      background-color: $solver-range-picker-handles-color;
      text-transform: unset;
      height: 41px;
      padding: unset;
      .MuiButton-label {
        color: $site-background-color;
      }
    }

    #run-solver-dialog-solve-button {
      grid-column: 29 / span 10;

      border: 1px solid $solver-range-picker-handles-color;
      text-transform: unset;
      height: 41px;
      padding: unset;
      .MuiButton-label {
        color: $solver-range-picker-handles-color;
      }
    }
  }

  .run-solver-dialog-options {
    display: grid;
    grid-row-gap: 1vh;
    padding: 1vh;

    .option {
      width: 100%;
    }
  }

  .select-option-heading {
    font-size: 16px;
    color: $filters-font-color;
    text-align: left;
    margin-bottom: 0.5vh;
  }

  #rule-set-select {
    .MuiSelect-select.MuiSelect-select {
      border: 1px solid $solver-modal-select-border-color;
      height: 4vh;
    }
    .MuiInputBase-input {
      font-size: 14px;
      height: 17px;
      color: $filters-font-color;
      padding: unset;
      padding-left: 15px;
      padding-top: 10px;
    }
    .MuiSelect-icon {
      padding-right: 10px;
    }
    .MuiTextField-root .MuiOutlinedInput-root {
      height: 50px;
      border-radius: 0;
    }

    .MuiInput-root {
      width: 100%;
    }
  }

  .fix-open-flights-checkbox {
    color: $filters-font-color;

    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    place-items: center;
  }

  .to-from-heading {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: $filters-font-color;
    grid-gap: 30px;
    font-size: 14px;
  }

  .to-from {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;

    .from-date {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      grid-gap: 10px;

      .MuiInputBase-root {
        border: 1px solid $solver-modal-header-border-color;
        padding-left: 10px;
        color: $filters-font-color;
      }

      .heading {
        font-size: 10px;
        color: $filters-font-color;
      }

      .date-div {
        position: relative;
        padding: 5px;
        border: 1px solid $solver-modal-header-border-color;
        color: $filters-font-color;

        .calendar-div {
          position: absolute;
          border: 1px solid $solver-modal-header-border-color;
          background: $reserve-duty-background-color;
          width: 250px;
          z-index: 10;
          top: 0;
        }
      }
    }
  }
}
